// extracted by mini-css-extract-plugin
export var SliderWidth = "OurWork-module--SliderWidth--e65c0";
export var Trust = "OurWork-module--Trust--bb924";
export var bottomLine = "OurWork-module--bottomLine--aee40";
export var btn = "OurWork-module--btn--93883";
export var btn2 = "OurWork-module--btn2--de16b";
export var clientImgs = "OurWork-module--clientImgs--bd170";
export var content = "OurWork-module--content--c7ab9";
export var devOpsRefineImages = "OurWork-module--devOpsRefineImages--4bbcd";
export var h3 = "OurWork-module--h3--df272";
export var headSearch = "OurWork-module--headSearch--7814e";
export var heading = "OurWork-module--heading--a4cf0";
export var logoBtn = "OurWork-module--logoBtn--f3b47";
export var logos = "OurWork-module--logos--a692d";
export var overly = "OurWork-module--overly--1892b";
export var portfolios = "OurWork-module--portfolios--ea746";
export var ser = "OurWork-module--ser--c9057";
export var talkBtn = "OurWork-module--talkBtn--357ee";
export var talkBtn2 = "OurWork-module--talkBtn2--92df7";
export var topLine = "OurWork-module--topLine--37893";