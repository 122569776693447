// extracted by mini-css-extract-plugin
export var Heading = "banner-module--Heading--3c189";
export var bannerCon = "banner-module--bannerCon--bdb78";
export var bannerHeading = "banner-module--bannerHeading--f5edd";
export var bannerVideo = "banner-module--bannerVideo--af7a0";
export var btn = "banner-module--btn--2cc55";
export var description = "banner-module--description--06717";
export var fintech = "banner-module--fintech--21175";
export var num = "banner-module--num--dc0cd";
export var numText = "banner-module--numText--906c1";
export var portfolioAppWorkBanner = "banner-module--portfolioAppWorkBanner--cee7d";
export var stat = "banner-module--stat--40966";
export var statsContainer = "banner-module--statsContainer--81fc3";
export var subTitle = "banner-module--subTitle--1f791";