import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./EngagementModels.module.scss"
import { Link } from "gatsby"

const EngagementModels = ({ strapiData, strapiData2 }) => {
  return (
    <div className={styles.engagementModels}>
      <div className={styles.innerModels}>
        <Container>
          <div>
            <h2 className={styles.title}>{strapiData?.subTitle}</h2>
            <div
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            />
          </div>
          <Row>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={4} key={i} className={styles.colCards}>
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={v?.image1 && v?.image1[0]?.localFile?.publicURL}
                    className={styles.icon}
                    style={{ height: "80px", width: "80px" }}
                  />
                  <h3 className={styles.title1}>{v?.title}</h3>
                  <div className={styles.description1}>{v?.subTitle}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Container className={styles.outerBanner}>
        <div className={styles.banner}>
          <p>{strapiData2?.title}</p>
          <Link to={strapiData2?.buttons[0]?.url} className={styles.btn}>
            {strapiData2?.buttons[0]?.title}
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
              width={24}
              height={24}
            />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default EngagementModels
