import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./TargetAudience.module.scss"

const TargetAudience = ({ strapiData }) => {
  return (
    <div className={`${styles.targetAudience} `}>
      <Container>
        <div className={styles.heading}>
          <p
            className={styles.subTitle}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          ></p>
          <h2
            className={`${styles.bannerHeading} `}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle2,
            }}
          />
        </div>
        {strapiData?.cards?.map((v, i) => (
          <div className={styles.card} key={i}>
            <h3 className={styles.heading}>{v?.title}</h3>
            <p className={styles.para}>{v?.subTitle}</p>
            <div className={styles.innerCard}>
              <p className={styles.imgText}>{v?.subTitle2}</p>
              <img
                src={v?.image1 && v?.image1[0]?.localFile?.publicURL}
                alt="Startup-Techonology-Partner-Asset"
                loading="lazy"
                width="960"
                height="350"
              />
            </div>
          </div>
        ))}
      </Container>
    </div>
  )
}

export default TargetAudience
