import React, { useRef } from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./FundingPlan.module.scss"
import Slider from "react-slick"

const FundingPlan = ({ strapiData }) => {
  const sliderRef = useRef(null)

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 2,
    speed: 500,
    dots: false,
    arrows: false,
    swipeToSlide: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.fundingPlan}>
      <div className={styles.innerPlan}>
        <Container>
          <div>
            <h2 className={styles.title}>{strapiData?.subTitle}</h2>
            <div className={styles.subTitleOuter}>
              <div
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
              <div className={styles.sliderBtns}>
                <button
                  className={styles.leftBtn}
                  onClick={() => sliderRef.current.slickPrev()}
                >
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_9b7e963c46.svg"
                    alt="arrow left"
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                </button>
                <button
                  className={styles.rightBtn}
                  onClick={() => sliderRef.current.slickNext()}
                >
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_9b7e963c46.svg"
                    alt="arrow left"
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                </button>
              </div>
            </div>
          </div>
        </Container>
        {/* <Row> */}
        <Container className="px-0">
          <Slider {...settings} className={styles.slideDiv} ref={sliderRef}>
            {strapiData?.cards?.map((e, i) => (
              <div
                key={i}
                className={styles.slideDivInner}
                // onMouseEnter={() => sliderRef.current.slickPause()}
                // onMouseLeave={() => sliderRef.current.slickPlay()}
              >
                <div className={styles.card}>
                  <p className={styles.step}>
                    0{i + 1}
                    <span>/</span>
                  </p>
                  <div className={styles.title2}>{e?.title}</div>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </div>
            ))}
          </Slider>
          {/* <Col lg={6}>
              <div className={styles.card}>
                <p className={styles.step}>
                  01<span>/</span>
                </p>
                <h3 className={styles.title}>Pre-seed</h3>
                <div className={styles.description}>
                  <ul>
                    <li>Nurturing initial ideas into viable businesses.</li>
                    <li>Guiding product development.</li>
                    <li>A strong foundation for startup success.</li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.card}>
                <p className={styles.step}>
                  02<span>/</span>
                </p>
                <h3 className={styles.title}>Pre-seed</h3>
                <div className={styles.description}>
                  <ul>
                    <li>Nurturing initial ideas into viable businesses.</li>
                    <li>Guiding product development.</li>
                    <li>A strong foundation for startup success.</li>
                  </ul>
                </div>
              </div>
            </Col> */}
          {/* </Row> */}
        </Container>
      </div>
    </div>
  )
}

export default FundingPlan
