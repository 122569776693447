import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Services.module.scss"

const Services = ({ strapiData }) => {
  return (
    <div className={`${styles.saga} `}>
      <Container>
        <Row className="align-items-center ">
          <Col lg={12}>
            <div className={styles.Heading}>
              <p
                className={styles.subTitle}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              ></p>
              <h2
                className={`${styles.bannerHeading} `}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle2,
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className={styles.cardRow}>
          {strapiData?.cards?.map((v, i) => (
            <Col ml={6} lg={4} key={i} className={styles.card}>
              <div>
                <lottie-player
                  autoplay
                  loop
                  src={v?.image1 && v?.image1[0]?.localFile?.publicURL}
                  className={styles.icon}
                  style={{ height: "60px", width: "60px" }}
                />
                <h3 className={styles.title}>{v?.title}</h3>
                <p className={styles.description}>{v?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Services
