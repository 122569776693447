import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./banner.module.scss"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.portfolioAppWorkBanner}>
      <Container className={styles.bannerCon}>
        <div className={styles.fintech}>
          <Container>
            <Row className="align-items-center">
              <Col xl={6} lg={12}>
                <div className={styles.Heading}>
                  <p
                    className={styles.subTitle}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle2,
                    }}
                  ></p>
                  <h1
                    className={styles.bannerHeading}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.title,
                    }}
                  />
                  <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle,
                    }}
                  />
                </div>
                <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
                  {strapiData?.buttons[0]?.title}
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                    decoding="async"
                    loading="lazy"
                    alt="explore icon"
                    width={24}
                    height={24}
                  />
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className={styles.statsContainer}>
                  {strapiData?.cards?.map((v, i) => (
                    <div className={styles.stat} key={i}>
                      <p className={styles.num}>{v?.title}</p>
                      <p className={styles.numText}>{v?.subTitle}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
          <div className={styles.bannerVideo}>
            <video
              autoPlay
              muted
              loop
              poster="https://invozone-backend.s3.us-east-1.amazonaws.com/bg_bc1921be1d.webp"
            >
              <source
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Cover_2_f88d9fc05b.webm"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Banner
