import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./FocusedServices.module.scss"

const FocusedServices = ({ strapiData }) => {
  return (
    <div className={styles.focusedServices}>
      <div className={styles.innerServices}>
        <Container>
          <div>
            <p className={styles.title}>{strapiData?.subTitle}</p>
            <h2
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            />
          </div>
          <Row className={styles.rowCards}>
            {strapiData?.cards?.map((v, i) => (
              <Col lg={6} key={i} className={styles.colCards}>
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={v?.image1 && v?.image1[0]?.localFile?.publicURL}
                    className={styles.icon}
                    style={{ height: "80px", width: "80px" }}
                  />
                  <h3 className={styles.title1}>{v?.title}</h3>
                  <div className={styles.description1}>{v?.subTitle}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default FocusedServices
