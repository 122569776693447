import { graphql, Script } from "gatsby"
import React from "react"
import SEORevamp from "../components/common/SEO_Revamp"
import Banner from "../components/startup/Banner"
import Services from "../components/startup/Services"
import MainLayout from "../layouts/MainLayout"
import TargetAudience from "../components/startup/TargetAudience"
import FundingPlan from "../components/startup/FundingPlan"
import FocusedServices from "../components/startup/FocusedServices"
// import OurWork from "../components/startup/OurWork"
import EngagementModels from "../components/startup/EngagementModels"
import Faqs from "../components/common/Faqs2"
import ContactSales from "../components/common/ContactSales"
import OurWork from "../components/startup/OurWork"

const StartupPage = ({ data }) => {
  const bannerSection = data?.strapiPage?.sections[0]
  const services = data?.strapiPage?.sections[1]
  const fundingPlan = data?.strapiPage?.sections[2]
  const targetAudience = data?.strapiPage?.sections[3]
  const focusedServices = data?.strapiPage?.sections[4]
  const ourWork = data?.strapiPage?.sections[5]
  const engagementModels = data?.strapiPage?.sections[6]
  const banner = data?.strapiPage?.sections[7]
  const faqs = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={bannerSection} />
      <Services strapiData={services} />
      <FundingPlan strapiData={fundingPlan} />
      <TargetAudience strapiData={targetAudience} />
      <FocusedServices strapiData={focusedServices} />
      <OurWork strapiData={ourWork} />
      <EngagementModels strapiData={engagementModels} strapiData2={banner} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query startUp {
    strapiPage(slug: { eq: "startup-technology-partner" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default StartupPage

export const Head = ({ data }) => {
  const { metaTitle, metaDescription, schemas } = data?.strapiPage?.seo

  // let headSchema = []
  // const bodySchema = schemas?.filter(v => {
  //   return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  // })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      <SEORevamp title={metaTitle} description={metaDescription} />
    </>
  )
}
