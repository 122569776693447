// extracted by mini-css-extract-plugin
export var card = "FundingPlan-module--card--7ad06";
export var description = "FundingPlan-module--description--a4ed6";
export var fundingPlan = "FundingPlan-module--fundingPlan--71565";
export var innerPlan = "FundingPlan-module--innerPlan--abfc8";
export var leftBtn = "FundingPlan-module--leftBtn--b02ac";
export var rightBtn = "FundingPlan-module--rightBtn--0b72e";
export var slideDiv = "FundingPlan-module--slideDiv--625c3";
export var slideDivInner = "FundingPlan-module--slideDivInner--dc7aa";
export var sliderBtns = "FundingPlan-module--sliderBtns--78542";
export var step = "FundingPlan-module--step--6b8d4";
export var subTitle = "FundingPlan-module--subTitle--ea23f";
export var subTitleOuter = "FundingPlan-module--subTitleOuter--1c85a";
export var title = "FundingPlan-module--title--f0511";
export var title2 = "FundingPlan-module--title2--b7e22";